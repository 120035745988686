.hero {
  min-height: 100vh;
  width: 100vw;
  position: relative;


  .hero-container {
    max-width: var(--sl);
    height: 100vh !important;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 50%;
      height: 100%;
      padding-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      .left-box {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        h1 {
          font-size: 45px;
        }

        .btns {
          display: flex;
          align-items: center;
          gap: 30px;
        }
      }
    }

    .right {
      width: 50%;
      height: 100%;
      padding: 80px 0px 20px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .grid-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        height: 100%;
        aspect-ratio: 3 / 4;

        .grid-block {
          // border: 1px solid var(--accent);
        }

        .grid-block1 {
          grid-area: 1 / 1 / 2 / 2;
          border-radius: 0px 50% 0px 0px;
          background: var(--accent);
          animation: b1 linear 5s infinite;
          animation-direction: alternate;
          animation-delay: 0s;

          @keyframes b1 {
            0% {
              transform:  rotate(0deg);
            }
            15% {
              transform: rotate(0deg);
              border-radius:  0px 50% 0px 0px;
            }
            17%{
              transform: rotate(90deg);
              border-radius: 50%;
            }
            20%{
              transform:  rotate(90deg);
              border-radius: 0px;
            }
            30%{
              transform:  rotate(90deg);
              border-radius: 50% 0px 0px 0px;
            }
            100%{
              transform:  rotate(90deg);
              border-radius: 50% 0px 0px 0px;;
            }
          }
        }

        .grid-block2 {
          grid-area: 1 / 2 / 2 / 3;
          border-radius: 0px 0px 50% 0px;
          background: var(--red);
          animation: b2 linear 5s infinite;
          animation-direction: alternate;
          animation-delay: 1.2s;

          @keyframes b2 {
            0% {
              transform: rotate(0deg);
            }
            15% {
              transform: rotate(0deg);
              border-radius: 0px 0px 50% 0px;
            }
            17%{
              transform: rotate(90deg);
              border-radius: 50%;
            }
            20%{
              transform: rotate(90deg);
              border-radius: 0px;
            }
            30%{
              transform:  rotate(90deg);
              border-radius: 0px 50% 0px 0px;
            }
            100%{
              transform:  rotate(90deg);
              border-radius: 0px 50% 0px 0px;
            }
          }
        }

        .grid-block3 {
          border-radius: 50% 0px 0px 0px;
          background: var(--black);
          animation: b3 linear 5s infinite;
          animation-direction: alternate;
          animation-delay: 2.4s;

          @keyframes b3 {
            0% {
              transform:  rotate(0deg);
            }
            15% {
              transform:  rotate(0deg);
              border-radius:  50% 0px 0px 0px;
            }
            17%{
              transform:  rotate(90deg);
              border-radius: 50%;
            }
            20%{
              transform:  rotate(90deg);
              border-radius: 0px;
            }
            30%{
              transform:  rotate(90deg);
              border-radius: 0px 0px 0px 50%;
            }
            100%{
              transform:  rotate(90deg);
              border-radius: 0px 0px 0px 50%;;
            }
          }
        }

        .grid-block4 {
          grid-area: 2 / 1 / 3 / 3;
          background: url("../img/1.webp");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          border-radius: 50% 0px 50% 0px;
          animation: b4 linear 5s infinite;
          animation-delay: 0s;
          animation-direction: alternate;

          @keyframes b4 {
            0% {
              border-radius: 0px 50% 0px 50%;
              transform: scale(1);
            }
          
          }
        }

        .grid-block5 {
          grid-area: 2 / 3 / 3 / 4;
          border-radius: 0px 0px 0px 50%;
          background: var(--accent);
          animation: b5 linear 5s infinite;
          animation-direction: alternate;
          animation-delay: 1.2s;

          @keyframes b5 {
            0% {
              transform: rotate(0deg);
            }
            15% {
              transform: rotate(0deg);
              border-radius: 0px 0px 0px 50%;
            }
            17%{
              transform: rotate(90deg);
              border-radius: 50%;
            }
            20%{
              transform: rotate(90deg);
              border-radius: 0px;
            }
            30%{
              transform:  rotate(90deg);
              border-radius: 0px 0px 50% 0px;
            }
            100%{
              transform:  rotate(90deg);
              border-radius: 0px 0px 50% 0px;
            }
          }
        }

        .grid-block6 {
          grid-area: 3 / 1 / 4 / 2;
          border-radius: 50% 0px 0px;
          background: var(--black);
          animation: b6 linear 5s infinite;
          animation-direction: alternate;
          animation-delay: 2.4s;

          @keyframes b6 {
            0% {
              transform:  rotate(0deg);
            }
            15% {
              transform:  rotate(0deg);
              border-radius:  50% 0px 0px 0px;
            }
            17%{
              transform:  rotate(90deg);
              border-radius: 50%;
            }
            20%{
              transform:  rotate(90deg);
              border-radius: 0px;
            }
            30%{
              transform:  rotate(90deg);
              border-radius: 0px 0px 0px 50%;
            }
            100%{
              transform:  rotate(90deg);
              border-radius: 0px 0px 0px 50%;;
            }
          }
        }

        .grid-block7 {
          grid-area: 3 / 2 / 4 / 3;
          border-radius: 50%;
          background: var(--red);
          animation: b7 linear 5s infinite;
          animation-direction: alternate;
          animation-delay: 3.6s;

          @keyframes b7 {
            0% {
              transform: rotate(0deg);
            }
            15% {
              transform: rotate(0deg);
              border-radius: 50px;
            }
            17%{
              transform: rotate(90deg);
              border-radius: 50px;
            }
            20%{
              transform: rotate(90deg);
              border-radius: 50px;
            }
            30%{
              transform:  rotate(90deg);
              border-radius: 50%;
            }
            100%{
              transform:  rotate(90deg);
              border-radius: 50%;
            }
          }
        }

        .grid-block8 {
          grid-area: 3 / 3 / 4 / 4;
          border-radius: 50% 0px 0px;
          background: url("../img/2.webp");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          animation: b8 linear 5s infinite;
          animation-delay: 0s;
          animation-direction: alternate;

          @keyframes b8 {
            0% {
              border-radius: 0px 50% 0px 50%;
              transform: scale(1);
            }
          
          }
        }

        .grid-block9 {
          grid-area: 4 / 1 / 5 / 2;
          background: url("../img/3.webp");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          animation: b9 linear 5s infinite;
          animation-delay: 0s;
          animation-direction: alternate;

          @keyframes b9 {
            0% {
              border-radius: 50%;
              transform: scale(1);
            }
          
          }
        }

        .grid-block10 {
          grid-area: 4 / 2 / 5 / 3;
          background: var(--accent);
          animation: b10 linear 5s infinite;
          animation-direction: alternate;
          animation-delay: 4.8s;

          @keyframes b10 {
            0% {
              transform: rotate(0deg);
            }
            15% {
              transform: rotate(0deg);
              border-radius: 00px;
            }
            17%{
              transform: rotate(90deg);
              border-radius: 50%;
            }
            20%{
              transform: rotate(90deg);
              border-radius: 0px;
            }
            30%{
              transform:  rotate(90deg);
              border-radius: 0px;
            }
            100%{
              transform:  rotate(90deg);
              border-radius: 0px;
            }
          }
        }

        .grid-block11 {
          grid-area: 4 / 3 / 5 / 4;
          border-radius: 0px 50% 0px 50%;
          background: var(--red);
          animation: b11 linear 5s infinite;
          animation-direction: alternate;
          animation-delay: 4s;

          @keyframes b11 {
            0% {
              transform: rotate(0deg);
            }
            15% {
              transform: rotate(0deg);
              border-radius: 0px 50% 0px 50%;
            }
            17%{
              transform: rotate(90deg);
              border-radius: 50% 0px 50% 0px;
            }
            20%{
              transform: rotate(90deg);
              border-radius: 0px 50% 0px 50%;
            }
            30%{
              transform:  rotate(90deg);
              border-radius: 0px 50% 0px 50%;
            }
            100%{
              transform:  rotate(90deg);
              border-radius: 0px 50% 0px 50%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    .hero-container {
      gap: 5%;

      .right {
        width: 40% !important;
        padding: 70px 0px 0px 0px !important;
        align-items: flex-end;
        justify-content: flex-end !important;
      }

      .left {
        width: 55% !important;
        padding-right: 80px;
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    .hero-container {
      .right {
        width: 50% !important;
        padding: 70px 0px 0px 0px !important;
        align-items: center;
        justify-content: flex-end !important;

        .grid-box {
          height: 80%;

          .grid-block {
            // border: 1px solid var(--accent);
          }
        }
      }

      .left {
        width: 50% !important;
        padding-right: 10px;
      }
    }
  }

  @media only screen and (max-width: 950px) {
    .hero{
      background: red;
    }
    .hero-container {
      flex-direction: column-reverse;
      padding-top: 90px !important;
      height: fit-content !important;

      .right {
        width: 100% !important;
        height: 60vh !important;
        padding: 0px 0px 0px 0px !important;
        align-items: center;
        justify-content: center !important;

        .grid-box {
          height: 100%;

          .grid-block {
            // border: 1px solid var(--accent);
          }
        }
      }

      .left {
        width: 100% !important;
        height: 40vh !important;
        padding: 0px 10px !important;

        .left-box {
          align-items: center;

          p {
            text-align: center;
            width: 90%;
          }
          .btns{
            width: 100%;
            gap: 10px;
          }
        }
      }
    }
  }

  .textslider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(3deg);
    overflow: hidden;

    p {
      font-size: 105px;
      white-space: nowrap;
      text-wrap: nowrap !important;
      font-weight: 600;
      color: var(--white);
      // line-height: 100%;

      span {
        color: rgb(255, 255, 255);
        -webkit-text-fill-color: transparent;
        /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: rgba(0, 51, 255, 0.201);
      }

      .high {
        color: var(--accent);
      }
    }
  }

  .hero-container {
    max-width: var(--sl);
    margin: auto;
    padding: 0px 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.about {
  height: 100vh;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.046);
  overflow: hidden;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -100;

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .out-top {
      animation: rotate 20s linear infinite;
      transform-origin: 13px 25px;
    }

    .in-top {
      animation: rotate 10s linear infinite;
      transform-origin: 13px 25px;
    }

    .out-bottom {
      animation: rotate 25s linear infinite;
      transform-origin: 84px 93px;
    }

    .in-bottom {
      animation: rotate 15s linear infinite;
      transform-origin: 84px 93px;
    }
  }

  .about-container {
    max-width: var(--sl);
    height: 100%;
    margin: auto;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    p {
      max-width: 1000px;
      text-align: center;
    }
  }
}

.services {
  height: 100%;
  width: 100%;
  position: relative;

  .services-container {
    max-width: var(--sl);
    height: 100%;
    margin: auto;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  h2 {
    margin-bottom: 70px;
  }

  .service-box {
    width: 100%;
    display: grid;
    min-height: 500px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    .service {
      width: 300px;
      max-height: 170px;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-decoration: none;
      transition: all 0.2s ease-out;

      &:hover {
        background: rgba(0, 0, 0, 0.035);
        transform: translate(0px, -4px);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.212);
      }

      p {
        font-weight: 900;
        text-align: center;
        font-size: 16px;
        height: 30%;
      }

      .icons {
        position: relative !important;
        width: 100px;
        height: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: red;
        border-radius: 10px;

        img {
          height: 80px !important;
        }
      }

      &:nth-child(3) .icons img {
        height: 70%;
      }

      &:nth-child(5) .icons img {
        height: 80%;
      }

      &:nth-child(6) .icons img {
        height: 70%;
      }
    }
  }
}

// Responsive for Service
@media (max-width: 1222px) {
  .services .service-box {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    place-items: center;
  }
}

@media (max-width: 625px) {
  .services .service-box {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px 20px;
    .service {
      border: 1px solid rgba(0, 0, 0, 0.168);
      width: 90%;
    }
  }
}

.ourclients {
  height: fit-content;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.046);

  .ourclients-container {
    max-width: var(--sl);
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 100px 20px;
    gap: 20px;


    .clients {
      height: auto;
      width: 95%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      // overflow-x: scroll;
      // gap: 30px;
      flex-wrap: wrap;

      .img-box {
        height: 60px;
        width: 160px;
        background: rgba(255, 0, 0, 0.111);
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        opacity: var(--opacity);
        filter: invert(100%);

        &:nth-child(2) {
          background: url("../img/icons/HCC\ -Logo.png");
        }

        &:nth-child(1) {
          background: url("../img/icons/Hope.png");
        }

        &:nth-child(4) {
          background: url("../img/icons/love\ umrah.png");
        }

        &:nth-child(5) {
          background: url("../img/icons/pure\ indian\ cooking.png");
        }

        &:nth-child(3) {
          background: url("../img/icons/Tony\ Alan\ Estates.png");
        }
      }
    }

    .testimonials {
      height: fit-content !important;
      width: 90%;
      background: rgba(255, 255, 255, 0.556);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.082);
      padding: 80px 20px 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;

      .testimonial-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 70%;

        .quote {
          width: 100%;
          display: flex;

          .quote-content {
            text-align: center;
          }

          span {
            font-size: 40px;
            height: 40px;
            opacity: var(--opacity);

            &.snd {
              transform: rotate(180deg) !important;
            }
          }
        }

        p.by {
          font-weight: 800;
          transition: var(--med);
        }
      }

      .testimonial-nav {
        display: flex;
        width: 70%;
        justify-content: space-between;
        align-items: center;

        .pagination {
          font-weight: 900;
          font-size: 12px;
          opacity: var(--opacity);
        }

        .navbtn {
          height: 30px;
          display: flex;
          gap: 10px;

          span {
            font-size: 30px;
            opacity: var(--opacity);
            transition: var(--med);

            &:hover {
              opacity: 1;
            }

            &:nth-child(1) {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .ourclients{
    .ourclients-container{
      .clients{
        width: 100%;
        gap: 20px;
        .img-box{
          width: 25% !important;
        }
      }
      .testimonials{
        height: fit-content !important;
        padding: 40px 10px 20px 10px ;
        width: 100%;
        .testimonial-content{
          width: 95%;
          padding-bottom: 50px;
        }
      }

    }
  }
}

// @media only screen and (max-width: 1100px) {
//   .ourclients {
//     height: 100vh;

//     .ourclients-container {
//       .clients {
//         justify-content: space-evenly;
//         // overflow-x: scroll;
//         // gap: 30px;
//         flex-wrap: wrap;

//         .img-box {
//           height: 60px;
//           width: 160px;
//         }
//       }

//       .testimonials {
//         height: 400px;
//         width: 90%;

//         .testimonial-content {
//           width: 90%;
//         }

//         .testimonial-nav {
//           display: flex;
//           width: 90%;
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 768px) {
//   .ourclients {
//     height: 100vh;

//     .ourclients-container {
//       .clients {
//         // justify-content: space-evenly;
//         // overflow-x: scroll;
//         gap: 10px;
//         flex-wrap: wrap;

//         .img-box {
//           height: 60px;
//           width: 130px;
//           margin: 0px 10px;
//         }
//       }

//       .testimonials {
//         height: 400px;
//         width: 100% !important;

//         .testimonial-content {
//           width: 100%;
//         }

//         .testimonial-nav {
//           display: flex;
//           width: 100%;
//         }
//       }
//     }
//   }
// }


.casestudy {
  width: 100%;
  height: fit-content;

  .casestudy-container {
    max-width: var(--sl);
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;

    h2 {
      width: 100%;
      text-align: left;
      margin-bottom: 50px;
    }

    .casestudy-box {
      display: flex;
      height: 100%;
      width: 70%;
      align-self: center;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 20px;
      padding: 20px;
      border-radius: 10px;

      .casestudy-element {
        cursor: pointer;
        overflow: hidden;
        height: 200px;
        width: 100%;
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.168);
        transition: var(--fast);

        &:hover {
          transform: scale(1.02);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.204);
          border: 2px solid var(--accent);
        }

        .image {
          height: 200px;
          width: 200px;
          background: var(--accent);
          background-position: center center !important;
          background-repeat: no-repeat !important;
          background-size: cover !important;
        }

        .content {
          width: calc(100% - 250px);
          height: 100%;
          padding: 20px;

          p.category {
            font-weight: 900;
            letter-spacing: normal;
            word-spacing: normal;
            text-align: left;
            font-size: 12px;
            opacity: 1;
            color: var(--accent);
            padding: 0px;
            margin: 0px;
            height: 20px;
            margin-bottom: 5px;
          }

          p.title {
            font-weight: 900;
            letter-spacing: normal;
            word-spacing: normal;
            text-align: left;
            font-size: 20px;
            opacity: 1;
            color: var(--black);
            line-height: 1.9ch;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .casestudy-box {
    h2 {
      width: 100%;
      text-align: center !important;
    }
  }
}
@media only screen and (max-width: 1150px) {
  .casestudy-box {
    width: 100% !important;
    padding: 100px 10px 10px !important;
  }
  .casestudy-box {
    gap: 30px !important;
    border: none !important;
    .casestudy-element {
      height: auto !important;
      min-width: 300px !important;
      border: 1px solid rgba(0, 0, 0, 0.168);
      display: flex;
      flex-direction: column;
      transition: var(--fast);
      &:hover {
        transform: scale(1.02);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.204);
        border: 2px solid var(--accent);
      }

      .image {
        height: 300px !important;
        width: 100% !important;
        background: var(--accent);
      }
      .content {
        width: 100% !important;
        height: fit-content !important;
        padding: 20px;

        p.category {
          font-weight: 900;
        }
        p.title {
          font-size: 16px !important;
        }
      }
    }
  }
}
