.layout{
    min-height: 100vh;
    .layout-container{
        max-width: var(--sl);
        margin: auto;
        min-height: 100vh;
        padding-top: 100px;
      

        .top{
            width: 100%;
            height: 450px;
            position: relative;
            border-radius: 20px;
            overflow: hidden !important;
            .imgbox {
                height: 100%;
                width: 100%;
                background: red;
                position: absolute;
                top: 0px;
                left: 0px;
                

            }
            .overlay{
                height: 100%;
                width: 100%;
                background: linear-gradient( 0deg ,black , transparent);
                position: absolute;
                top: 0px;
                left: 0px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                flex-direction: column;
                padding: 30px;
                h1{
                    color: var(--white);
                    font-size: 30px;
                }
            }
        }
        .mid{
            border-radius: 15px;
            padding: 20px 10px;
            width: 100%;
            margin: 10px auto;    
            border: 1px solid rgba(0, 0, 0, 0.174);

        }
        .bottom{
            padding-bottom: 50px;
height: auto !important;
            h2{
              
            }
            .data{
                h3{
                    
                }
            }

        }
    }
}