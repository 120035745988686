.about-hero {
  height: 300px;
  .about-container {
    height: 100%;
    max-width: var(--sl);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      font-size: 35px !important;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 800px) {

  .about-hero{
    .about-container{
      h1{
        font-size: 20px !important;
        width: 80%;
      }
    }
  }

}



.journey {
  background: #f3e8dc;
  height: 500px;

  .journey-container {
    height: 100%;
    max-width: var(--sl);
    padding: 0px 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      p{
        text-align: left;
      }
    }
    .right {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .right-box {
        width: 90%;
        height: 70%;
        background: url('../img/diwise_poster.png');
        border: 1px solid rgba(0, 0, 0, 0.074);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.152);
        border-radius: 20px;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .journey{
    min-height: 400px;
  }
}
@media only screen and (max-width: 1100px) {
  .journey-container{
    flex-direction: column-reverse;
    .left ,.right{
      width: 100% !important;
      height: 50% !important;
    }
    .left{
      h2{
        width: 100%;
        text-align: center;
      }
      p{
        text-align: center !important;
      }
    }
    .right{
      .right-box{
        width: 100% !important;
        height: 70% !important;
      }
       
    }

  }
    
}




.leadership {
  min-height: 600px;
  .leadership-conatiner {
    height: 100%;
    max-width: var(--sl);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 80px;
    .leader-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 70px 0px;

      p {
        text-align: center !important;
        padding-top: 15px;
        width: 90%;
      }
    }
    .leader-bottom {
      display:flex;
      align-items: center;
      justify-content: stretch;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .leader-box {
        display: flex;
        align-items: stretch;
        justify-content: space-evenly;
        transition: var(--med2);
        gap: 20px;
        width: 80%;
        border: 1px solid rgba(0, 0, 0, 0.174);
        border-radius: 10px;
        overflow: hidden;
        &:nth-child(1) {
          .leaderimg {
            .leader {
              background: url("../img/prajakta\ ma`am.webp");
            }
          }
        }
        &:nth-child(2) {
          .leaderimg {
            .leader {
              background: url("../img/prashant\ sir.webp");
            }
          }
        }
        &:nth-child(3) {
          .leaderimg {
            .leader {
              background: url("../img/rajveer\ sir.webp");
            }
          }
        }

        .leaderimg {
          width: 30%;
          background: linear-gradient(transparent 40%, var(--accent) 35%);
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: column;
          transition: var(--med2);
          overflow: hidden;
          .leader {
            height: 100%;
            width: auto;
            aspect-ratio: 1/1;
            background-position: center bottom !important;
            background-size: contain !important;
            background-repeat: no-repeat !important;
            filter: grayscale(100%) drop-shadow(5px 5px 1px #0000006f)
              contrast(1);
            transition: var(--med2);
          }
        }
        .leader-content {
          width: 70%;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          padding: 15px 20px;
          gap: 5px;

          .head {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            gap: 2px;
            h3 {
            }
            h5 {
              font-style: italic;
              opacity: 0.7;
              font-size: 14px;
            }
          }
          .divider {
            height: 3px;
            border-radius: 20px;
            background: var(--accent);
            width: 40%;
            margin-top: 5px;
            transition: var(--med2);
          }
          p {
            font-size: 16px !important;
            line-height: 130%;
          }
          button.linkedin {
            border: none;
            background: #087bb9;
            font-weight: 500;
            padding: 7px 7px;
            color: var(--white);
            margin-top: 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            font-size: 16px;
            transition: var(--med2);
            &:hover {
              transform: scale(1.1);
            }
          
            a{
              text-decoration: none !important;
              display: flex !important;
              align-items: center;
              color: white;
              flex-direction: row-reverse;
            }
            span {
              margin-right: 10px; //hover change
              transition: var(--med2);
            }
            .linkin-icon {
              background: var(--white);
              width: 30px !important;
              height: 30px !important;
              font-size: 20px;
              border-radius: 5px;
              color: #087bb9;
              display: grid;
              place-items: center;
            }
          }
        }

        &:hover {
          transform: translateY(5px);
          .leaderimg {
            .leader {
              transform: scale(1.2);
              filter: grayscale(0%) drop-shadow(5px 5px 5px #0000006f)
                contrast(1);
            }
          }

          .leader-content {
            .divider {
              width: 80%;
            }
            .linkedin {
              font-size: 16px;
              span {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1100px) {
  .leader-bottom{

    .leader-box {
        flex-direction: column;
        width: 65% !important;
    
        .leaderimg {
          width: 100% !important;
          height: 300px !important;
        }
        .leader-content {
          width: 100% !important;
          height: fit-content !important;
          align-items: center !important;
          .head {
            align-items: center !important;
            h5{
                font-size: 12px !important;
            }
          }
          p {
            text-align: center !important;
            padding: 10px 0px;
          }
        }
      }
  }
}


@media only screen and (max-width: 900px) {
    .leader-bottom{
      .leader-box {
     
          width: 75% !important;
      
          
    }
  }
  }
@media only screen and (max-width: 700px) {
    .leader-bottom{
      .leader-box {
     
          width: 95% !important;
      
          
    }
  }
  }


.expertise{
  background: #f3e8dc;
  padding: 40px 0px;

  .expertise-container{
    
    max-width: var(--sl);
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p.des{
      width: 70%;
      text-align: center;
      padding: 20px 0px;
    }
    .service-list{
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 20px;
 

      .service-item{
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid rgba(136, 134, 134, 0.725);
        border-radius: 5px;
        text-decoration: none ;

        .icon{

          img{
            height: 50px;
            margin: 5px 10px;
          }
        }
        p{
          font-weight: bold;
          text-align: left;
        }
      }

    }

  }
}

@media only screen and (max-width: 1200px) {
  .expertise{
    .expertise-container{
      .service-list{
        width: 90%;
        .service-item{
          width: 45% !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 820px) {
  .expertise{
    .expertise-container{
      .service-list{
        width: 90%;
        .service-item{
          width: 95% !important;
        }
      }
    }
  }
}

.last-section{
  padding: 70px 0px 0px 0px;
  
  .last-section-container{
    max-width: calc( var(--sl) - 100px);
    padding: 0px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    align-items: stretch;
    padding: 0px 20px;

    .last-section-left{
      background: #f3e8dc;
      width: 50%;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      p{
        text-align: center;
      }
    }
    .last-section-right{
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 50%;
     .last-section-right-top{
      background: #f3e8dc;
      height:50%;
      width: 100%;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      p{
        text-align: center;
      }
     }
     .last-section-right-bottom{
      background: #f3e8dc;
      height:50%;
      width: 100%;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      p{
        text-align: center;
      }
     }
    }


  }
}


@media only screen and (max-width: 1000px) {

  .last-section{
    padding: 20px 0px 0px 0px;
    margin-bottom: -50px;
  }

  .last-section-container{
    flex-direction: column;
    .last-section-left , .last-section-right{
      width: 100% !important;

    }
  }
  
}