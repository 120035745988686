.beforefooter {
  height: 500px;
  width: 100%;
  position: relative;
  padding: 80px 0px 20px 0px;
}
.beforefooter .bgfoot {
  position: absolute;
  bottom: 0px;
  height: 100px;
  width: 100%;
  background: var(--black);
  z-index: -1;
}
.beforefooter .hook-box {
  height: 400px;
  max-width: calc(var(--sl) - 100px);
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 20px;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.572);
  box-shadow: 0px 2px 20px rgba(255, 255, 255, 0.16);
}
.beforefooter .hook-box video {
  z-index: -1;
  filter: blur(3px);
}
.beforefooter .hook-box h3 {
  color: var(--white);
}
.beforefooter .hook-box p {
  margin: 20px;
  color: var(--white);
}
.beforefooter .hook-box .btn {
  color: var(--white);
  border: 2px solid white;
}/*# sourceMappingURL=beforefooter.css.map */