.contact{
    width: 100%;
    height: 100vh;
    
    .contact-container{
        max-height: var(--sl);
        height: 100vh;


        .contact-box{

            .box{
                

            }



        }
    }


}