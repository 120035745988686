.loader_container 
{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    overflow-y: hidden;  
    img 
    {
        width: 200px;
        height: 200px;
    }
}