.about-hero {
  height: 300px;
}
.about-hero .about-container {
  height: 100%;
  max-width: var(--sl);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-hero .about-container h1 {
  font-size: 35px !important;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .about-hero .about-container h1 {
    font-size: 20px !important;
    width: 80%;
  }
}
.journey {
  background: #f3e8dc;
  height: 500px;
}
.journey .journey-container {
  height: 100%;
  max-width: var(--sl);
  padding: 0px 20px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.journey .journey-container .left {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.journey .journey-container .left p {
  text-align: left;
}
.journey .journey-container .right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.journey .journey-container .right .right-box {
  width: 90%;
  height: 70%;
  background: url("../img/diwise_poster.png");
  border: 1px solid rgba(0, 0, 0, 0.074);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.152);
  border-radius: 20px;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

@media only screen and (max-width: 1300px) {
  .journey {
    min-height: 400px;
  }
}
@media only screen and (max-width: 1100px) {
  .journey-container {
    flex-direction: column-reverse;
  }
  .journey-container .left, .journey-container .right {
    width: 100% !important;
    height: 50% !important;
  }
  .journey-container .left h2 {
    width: 100%;
    text-align: center;
  }
  .journey-container .left p {
    text-align: center !important;
  }
  .journey-container .right .right-box {
    width: 100% !important;
    height: 70% !important;
  }
}
.leadership {
  min-height: 600px;
}
.leadership .leadership-conatiner {
  height: 100%;
  max-width: var(--sl);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 80px;
}
.leadership .leadership-conatiner .leader-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px 0px;
}
.leadership .leadership-conatiner .leader-top p {
  text-align: center !important;
  padding-top: 15px;
  width: 90%;
}
.leadership .leadership-conatiner .leader-bottom {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.leadership .leadership-conatiner .leader-bottom .leader-box {
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  transition: var(--med2);
  gap: 20px;
  width: 80%;
  border: 1px solid rgba(0, 0, 0, 0.174);
  border-radius: 10px;
  overflow: hidden;
}
.leadership .leadership-conatiner .leader-bottom .leader-box:nth-child(1) .leaderimg .leader {
  background: url("../img/prajakta ma`am.webp");
}
.leadership .leadership-conatiner .leader-bottom .leader-box:nth-child(2) .leaderimg .leader {
  background: url("../img/prashant sir.webp");
}
.leadership .leadership-conatiner .leader-bottom .leader-box:nth-child(3) .leaderimg .leader {
  background: url("../img/rajveer sir.webp");
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leaderimg {
  width: 30%;
  background: linear-gradient(transparent 40%, var(--accent) 35%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  transition: var(--med2);
  overflow: hidden;
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leaderimg .leader {
  height: 100%;
  width: auto;
  aspect-ratio: 1/1;
  background-position: center bottom !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  filter: grayscale(100%) drop-shadow(5px 5px 1px rgba(0, 0, 0, 0.4352941176)) contrast(1);
  transition: var(--med2);
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 15px 20px;
  gap: 5px;
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content .head {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content .head h5 {
  font-style: italic;
  opacity: 0.7;
  font-size: 14px;
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content .divider {
  height: 3px;
  border-radius: 20px;
  background: var(--accent);
  width: 40%;
  margin-top: 5px;
  transition: var(--med2);
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content p {
  font-size: 16px !important;
  line-height: 130%;
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content button.linkedin {
  border: none;
  background: #087bb9;
  font-weight: 500;
  padding: 7px 7px;
  color: var(--white);
  margin-top: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  font-size: 16px;
  transition: var(--med2);
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content button.linkedin:hover {
  transform: scale(1.1);
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content button.linkedin a {
  text-decoration: none !important;
  display: flex !important;
  align-items: center;
  color: white;
  flex-direction: row-reverse;
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content button.linkedin span {
  margin-right: 10px;
  transition: var(--med2);
}
.leadership .leadership-conatiner .leader-bottom .leader-box .leader-content button.linkedin .linkin-icon {
  background: var(--white);
  width: 30px !important;
  height: 30px !important;
  font-size: 20px;
  border-radius: 5px;
  color: #087bb9;
  display: grid;
  place-items: center;
}
.leadership .leadership-conatiner .leader-bottom .leader-box:hover {
  transform: translateY(5px);
}
.leadership .leadership-conatiner .leader-bottom .leader-box:hover .leaderimg .leader {
  transform: scale(1.2);
  filter: grayscale(0%) drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.4352941176)) contrast(1);
}
.leadership .leadership-conatiner .leader-bottom .leader-box:hover .leader-content .divider {
  width: 80%;
}
.leadership .leadership-conatiner .leader-bottom .leader-box:hover .leader-content .linkedin {
  font-size: 16px;
}
.leadership .leadership-conatiner .leader-bottom .leader-box:hover .leader-content .linkedin span {
  margin-right: 10px;
}

@media only screen and (max-width: 1100px) {
  .leader-bottom .leader-box {
    flex-direction: column;
    width: 65% !important;
  }
  .leader-bottom .leader-box .leaderimg {
    width: 100% !important;
    height: 300px !important;
  }
  .leader-bottom .leader-box .leader-content {
    width: 100% !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    align-items: center !important;
  }
  .leader-bottom .leader-box .leader-content .head {
    align-items: center !important;
  }
  .leader-bottom .leader-box .leader-content .head h5 {
    font-size: 12px !important;
  }
  .leader-bottom .leader-box .leader-content p {
    text-align: center !important;
    padding: 10px 0px;
  }
}
@media only screen and (max-width: 900px) {
  .leader-bottom .leader-box {
    width: 75% !important;
  }
}
@media only screen and (max-width: 700px) {
  .leader-bottom .leader-box {
    width: 95% !important;
  }
}
.expertise {
  background: #f3e8dc;
  padding: 40px 0px;
}
.expertise .expertise-container {
  max-width: var(--sl);
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.expertise .expertise-container p.des {
  width: 70%;
  text-align: center;
  padding: 20px 0px;
}
.expertise .expertise-container .service-list {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.expertise .expertise-container .service-list .service-item {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid rgba(136, 134, 134, 0.725);
  border-radius: 5px;
  text-decoration: none;
}
.expertise .expertise-container .service-list .service-item .icon img {
  height: 50px;
  margin: 5px 10px;
}
.expertise .expertise-container .service-list .service-item p {
  font-weight: bold;
  text-align: left;
}

@media only screen and (max-width: 1200px) {
  .expertise .expertise-container .service-list {
    width: 90%;
  }
  .expertise .expertise-container .service-list .service-item {
    width: 45% !important;
  }
}
@media only screen and (max-width: 820px) {
  .expertise .expertise-container .service-list {
    width: 90%;
  }
  .expertise .expertise-container .service-list .service-item {
    width: 95% !important;
  }
}
.last-section {
  padding: 70px 0px 0px 0px;
}
.last-section .last-section-container {
  max-width: calc(var(--sl) - 100px);
  padding: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  align-items: stretch;
  padding: 0px 20px;
}
.last-section .last-section-container .last-section-left {
  background: #f3e8dc;
  width: 50%;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.last-section .last-section-container .last-section-left p {
  text-align: center;
}
.last-section .last-section-container .last-section-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.last-section .last-section-container .last-section-right .last-section-right-top {
  background: #f3e8dc;
  height: 50%;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.last-section .last-section-container .last-section-right .last-section-right-top p {
  text-align: center;
}
.last-section .last-section-container .last-section-right .last-section-right-bottom {
  background: #f3e8dc;
  height: 50%;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.last-section .last-section-container .last-section-right .last-section-right-bottom p {
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .last-section {
    padding: 20px 0px 0px 0px;
    margin-bottom: -50px;
  }
  .last-section-container {
    flex-direction: column;
  }
  .last-section-container .last-section-left, .last-section-container .last-section-right {
    width: 100% !important;
  }
}/*# sourceMappingURL=about.css.map */