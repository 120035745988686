.footer-parent {
  padding: 10px 0px 30px 0px;
  background: var(--black);

  .footer-container {
    max-width: var(--wl);
    margin: auto;
    height: 100%;
    padding: 10px 20px 0px 20px;
    .footer-top {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;

      .col {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .title {
          color: var(--white);
          padding-bottom: 10px;
        }
        a {
          text-decoration: none;
          color: rgba(255, 255, 255, 0.268);
          transition: var(--fast);
          &:hover {
            color: var(--accent);
          }
        }
      }

      .col1 {
        .logo {
          img {
            height: 35px;
          }
          margin-bottom: 10px;
        }
        a{
          display: flex;
          align-items: center;
          gap: 10px;
          span{
            font-size: 20px;
            
          }
          &:nth-child(3){
            span{
              font-size: 27px;
            }
          }
        }
      }
    }
    .footer-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba(255, 255, 255, 0.129);
      padding: 10px 0px 0px 0px;
      margin: 10px 0px 0px 0px;
      .copy {
      }
      .social {
        display: flex;
        align-items: center;
        gap: 15px;
        a {
          text-decoration: none;
          color: rgba(255, 255, 255, 0.268);
          transition: var(--fast);
          
          &:nth-child(1){
            font-size: 20px;
          }
          &:nth-child(2){
            font-size: 19px;
          }
          &:nth-child(3){
            font-size: 24px;
          }
          &:nth-child(4){
            font-size: 22px;
          }
          &:hover {
            color: var(--accent);
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 800px) {
  
.footer-container 
{
  display: flex;
  flex-wrap: wrap;
  .footer-top
  {
    flex-wrap: wrap;
    width: 100%;

    .col 
    {
      width: 45%;
    }
 
  }
  .footer-bottom 
  {
    width: 100%;
  }
}

}
@media only screen and (max-width: 500px) {
  
.footer-container 
{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .footer-top
  {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 10px;
    .col 
    {
      align-items: center;
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.129);
      padding: 20px 0px;

      &:nth-child(1) 
      {
        border-top: none !important;
      }
      &:last-child 
      {
        border-bottom: none !important;
      }
      
    }
 
  }
  .footer-bottom
  {
    flex-direction: column-reverse;
    gap: 20px;
    align-items: center;
  }
}

}