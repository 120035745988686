.navbar {
  height: 60px;
  width: 100vw;
  top: 20px;
  position: fixed;
  z-index: 1000;
  transition: var(--med);
}
.navbar.scrolled {
  background: rgba(0, 0, 0, 0.361);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  top: 0px;
  height: 50px;
}
.navbar.scrolled .navbar-container .logo {
  background: url("../img/logowhite.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.navbar.scrolled .navbar-container .menu span {
  background: var(--white);
}
.navbar .navbar-container {
  max-width: var(--sl);
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}
.navbar .navbar-container .logo {
  height: 100%;
  width: 100px;
  background: url("../img/logoblack.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  cursor: pointer;
  transition: var(--med);
}
.navbar .navbar-container .logo.navlogo {
  background: url("../img/logowhite.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.navbar .navbar-container .menu {
  height: 40px;
  width: 40px;
  position: relative;
  transition: var(--slow);
}
.navbar .navbar-container .menu span {
  position: absolute;
  height: 3px;
  width: 35px;
  background: var(--black);
  transition: var(--slow);
  transform: translateY(-50%);
}
.navbar .navbar-container .menu span:nth-child(1) {
  top: 30%;
}
.navbar .navbar-container .menu span:nth-child(2) {
  top: 50%;
  width: 25px;
}
.navbar .navbar-container .menu span:nth-child(3) {
  top: 70%;
}
.navbar .navbar-container .menu:hover {
  transform: rotate(0deg) scale(1.05);
}
.navbar .navbar-container .menu:hover span:nth-child(1), .navbar .navbar-container .menu:hover span:nth-child(3) {
  width: 35px;
}
.navbar .navbar-container .menu:hover span:nth-child(2) {
  width: 35px;
}
.navbar .navbar-container .menu.closeicon {
  transform: rotate(180deg);
}
.navbar .navbar-container .menu.closeicon span {
  background: var(--white);
}
.navbar .navbar-container .menu.closeicon span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.navbar .navbar-container .menu.closeicon span:nth-child(2) {
  top: 50%;
  width: 35px;
  transform: translateY(-50%) rotate(-45deg);
}
.navbar .navbar-container .menu.closeicon span:nth-child(3) {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.navbar .navbar-container .menu.closeicon:hover {
  transform: rotate(180deg) scale(1.05);
}

.sidenav {
  position: fixed;
  height: 100vw;
  width: 100vw;
  background: rgb(0, 0, 0);
  overflow: hidden;
  z-index: 500;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidenav .links {
  list-style-type: none;
  transition: var(--med);
}
.sidenav .links:hover li {
  padding: 5px 0px 5px 0px;
}
.sidenav .links:hover li a {
  opacity: 0.2;
}
.sidenav .links li {
  transition: var(--med);
  padding: 5px 0px;
}
.sidenav .links li a {
  transition: var(--med);
  text-decoration: none;
  color: var(--white);
  font-size: 50px;
  font-weight: 700;
}
.sidenav .links li a span {
  color: red;
  font-size: 100px;
  background: red;
}
.sidenav .links li a:hover {
  color: var(--white);
  opacity: 1;
}

@media only screen and (max-width: 720px) {
  .links li {
    padding: 10px 0px !important;
  }
  .links li a {
    font-size: 30px !important;
  }
}/*# sourceMappingURL=Header.css.map */