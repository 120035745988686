
.blog {
  width: 100%;
  min-height: 100vh;
  

  .blog-container {
    max-width: var(--sl);
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 40px 40px 40px;

    h2 {
      width: 100%;
      text-align: left;
      margin-bottom: 50px;
    }

    .blogs {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-evenly;
      height: 100%;
      width: 100%;
      gap: 30px;

      .filters-box {
        width: 30%;
        height: 100%;
        border: 1px solid rgba(0, 0, 0, 0.168);
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        top: 70px;
        position: sticky;
        position: -webkit-sticky;
        .search-box {
          display: flex;
          gap: 10px;
          input {
            width: 100%;
            border-radius: 5px;
            height: fit-content;
            padding: 10px 20px;
            outline: none;
            border: none;
            background: transparent;
            border: 2px solid rgb(0, 0, 0);
            font-weight: 500;
          }
          .search-icon {
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--black);
            border-radius: 5px;
            color: var(--white);
          }
        }

        .date {
          display: flex;
          gap: 10px;
          label {
            font-weight: 700;
          }
          input {
            width: 100%;
            border-radius: 5px;
            height: fit-content;
            padding: 10px 20px;
            outline: none;
            border: none;
            background: transparent;
            border: 2px solid rgb(0, 0, 0);
            font-weight: 500;
          }
        }
        select {
          width: 100%;
          border-radius: 5px;
          height: fit-content;
          padding: 10px 20px;
          outline: none;
          border: none;
          background: transparent;
          border: 2px solid rgb(0, 0, 0);
          font-weight: 500;
          option{
            width: 100% !important;
            border-radius: 5px !important;
            height: fit-content !important;
            padding: 10px 20px !important;
            outline: none !important;
            border: none !important;
            background: transparent !important;
            border: 2px solid rgb(0, 0, 0) !important;
            font-weight: 500 !important;
          }
        }

        .btns {
          display: flex;
          gap: 10px;
          .btn {
            width: 100%;
          }
        }
      }

      .blog-box {
        display: flex;
        height: 100%;
        width: 70%;
        align-self: center;
        justify-content: space-around;
        flex-wrap: wrap;
        gap:20px;
        padding: 20px;

        border-radius: 10px;
        .blog-element {
          cursor: pointer;
          overflow: hidden;
          height: 200px;
          width: 100%;
          display: flex;
          border: 1px solid rgba(0, 0, 0, 0.168);
          transition: var(--fast);
          &:hover {
            transform: scale(1.02);
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.204);
            border: 2px solid var(--accent);
          }

          .image {
            height: 200px;
            width: 200px;
            background: var(--accent);
            background-position: center center !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            
          }
          .content {
            width: calc(100% - 250px);
            height: 100%;
            padding: 20px;

            p.category {
              font-weight: 900;
              letter-spacing: normal;
              word-spacing: normal;
              text-align: left;
              font-size: 12px;
              opacity: 1;
              color: var(--accent);
              padding: 0px;
              margin: 0px;
              height: 20px;
              margin-bottom: 5px;
            }
            p.title {
              font-weight: 900;
              letter-spacing: normal;
              word-spacing: normal;
              text-align: left;
              font-size: 20px;
              opacity: 1;
              color: var(--black);
              line-height: 1.9ch;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
    .blog-container {
        h2{
            width: 100%;
            text-align: center !important;
        }
    }
 .blogs {
    flex-direction: column !important;
     
  }
  .filters-box{

    width: 100% !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    position: relative !important;
    top: 0px !important;
    .search-box{
        width: 100%;
    }
    .date{
        width: 100% !important;
        .from ,.to{
            width: 50%;
        }
    }

    .btns{
        width: 100% !important;
    }
  }
  .blog-box{
    width: 100% !important;
  }

}
@media only screen and (max-width: 850px) {
   .blog-container{width: 100% !important;padding:100px 10px 10px !important;}
    .blog-box{
        gap: 30px !important;
        border: none !important;
        .blog-element {
            height: auto !important;
            min-width: 300px !important;
                   border: 1px solid rgba(0, 0, 0, 0.168);
            display: flex;
            flex-direction: column ;
            transition: var(--fast);
            &:hover {
              transform: scale(1.02);
              box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.204);
              border: 2px solid var(--accent);
            }
    
            .image {
              height: 300px !important;
              width: 100% !important;
              background: var(--accent);
            }
            .content {
              width: 100% !important;
              height: fit-content !important;
              padding: 20px;
    
              p.category {
                font-weight: 900;
    
              }
              p.title {
                font-size: 16px !important; 
              }
            }
          }
    }

}

