.intro-parent {
  width: 100%;
  min-height: 50vh;
}
.intro-parent .introduction {
  margin: auto;
  border: 1px solid red;
  height: 100%;
  max-width: var(--sl);
  padding: 90px 0px 0px 0px;
}
.intro-parent .introduction section.opening {
  display: flex;
  border: 1px solid green;
  width: 100%;
  height: 100vh;
  align-items: center;
  gap: 20px;
  padding: 0px 20px;
  overflow: hidden;
}
.intro-parent .introduction section.opening .left {
  border: 1px solid red;
  width: 65%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 1/1;
  gap: 40px;
}
.intro-parent .introduction section.opening .left h1.intro-head {
  color: black;
  padding: 0px 20px;
  font-size: 30px;
}
.intro-parent .introduction section.opening .left p.intro-content {
  font-size: 18px;
  padding: 0px 20px;
  line-height: 150%;
  text-align: justify;
}
.intro-parent .introduction section.opening .right {
  background: rgba(255, 0, 0, 0.067);
  height: 100%;
  width: 35%;
}

.background-parent {
  max-width: var(--sl);
  border: 1px solid red;
  align-items: center !important;
  margin: auto;
}
.background-parent .background {
  text-wrap: wrap !important;
  margin: 20px 10px;
  text-align: center;
}
.background-parent .background h1 {
  font-size: 30px;
}

.objective-parent {
  height: 100%;
  padding: 40px 0px;
  max-width: var(--sl);
  text-align: center;
  border: 1px solid red;
  margin: auto;
}
.objective-parent .objective {
  text-wrap: wrap !important;
  margin: 20px 10px;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.objective-parent .objective h3 {
  font-size: 30px;
  font-weight: 600;
}
.objective-parent .objective .card-container {
  flex-wrap: wrap;
  gap: 20px;
  margin: 0px 20px;
  display: flex;
  position: relative;
  justify-content: center;
}
.objective-parent .objective .card {
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(245, 245, 245, 0.1607843137);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}
.objective-parent .objective .card-content {
  padding: 20px;
}
.objective-parent .objective .card-content h2 {
  font-size: 1.5rem;
  margin: 0;
}
.objective-parent .objective .card-content p {
  font-size: 1rem;
  color: #666;
  text-align: justify;
}

.solution-container {
  height: 100%;
  padding: 40px 0px;
  max-width: var(--sl);
  text-align: center;
  border: 1px solid red;
  margin: auto;
}
.solution-container .solution {
  text-wrap: wrap !important;
  margin: 20px 10px;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.solution-container .solution .card-container {
  display: flex;
  padding: 40px 0px 0px 0px;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}
.solution-container .solution .card {
  height: auto;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  background: rgba(255, 183, 0, 0.02);
  gap: 10px;
  margin-bottom: 20px;
}
.solution-container .solution .card-icon {
  font-size: 35px;
  width: 10%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.solution-container .solution .card-content {
  width: 90%;
  padding: 20px;
  text-align: left;
}
.solution-container .solution h2 {
  font-size: 18px;
  padding-bottom: 10px;
  margin: 0;
}
.solution-container .solution p {
  font-size: 1rem;
  color: #666;
}/*# sourceMappingURL=c1.css.map */