@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500");
:root {
  --accent: #eb7924;
  --accentlight: #84adfa;
  --accentdark: #171717;
  --black: #0e0e0e;
  --white: #fff;
  --red: #eb7a2469;
  --opacity: 0.3;
  --sl: 1400px;
  --wl: 1200px;
  --slow: all 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --med: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --med2: all 0.6s ease-out;
  --fast: all 0.33s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
  font-family: "Montserrat", sans-serif;
}

::-moz-selection {
  background: var(--accent);
  color: var(--white);
}

::selection {
  background: var(--accent);
  color: var(--white);
}

.bg-img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  overflow-x: hidden !important;
  height: auto;
  background: rgba(255, 214, 173, 0.285);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  color: var(--accent) !important;
}

@media only screen and (max-width: 1600px) {
  h1 {
    font-size: 3rem !important;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 1300px) {
  h1 {
    font-size: 2.5rem !important;
  }
  h2 {
    font-size: 1.8rem !important;
  }
  h3 {
    font-size: 1.3rem !important;
  }
  h4 {
    font-size: 1rem !important;
  }
  h5 {
    font-size: 0.8rem !important;
  }
  h6 {
    font-size: 0.6rem !important;
  }
}
@media only screen and (max-width: 950px) {
  h1 {
    font-size: 1.8rem !important;
    text-align: center !important;
  }
  h2 {
    font-size: 1.5rem !important;
    text-align: center !important;
  }
  h3 {
    font-size: 1.5rem !important;
    text-align: center !important;
  }
  h4 {
    font-size: 1.2rem !important;
    text-align: center !important;
  }
  h5 {
    font-size: 1rem !important;
    text-align: center !important;
  }
  h6 {
    font-size: 0.8rem !important;
    text-align: center !important;
  }
}
@media only screen and (max-width: 720px) {
  h1 {
    font-size: 1.5rem !important;
    font-weight: 800 !important;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.8rem;
  }
}
h1 {
  font-size: 5rem;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.1rem;
}

h6 {
  font-size: 0.8rem;
}

p {
  color: rgb(62, 62, 62);
  letter-spacing: 1px;
  font-size: 1.2em;
  line-height: 1.9rem;
}

@media only screen and (max-width: 1000px) {
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
  }
}
.btn {
  text-decoration: none;
  color: var(--black);
  background: transparent;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 18px;
  border: 2px solid var(--black);
  position: relative;
  transition: var(--fast);
}
.btn:hover {
  transform: translate(-2px, -2px);
  box-shadow: 4px 4px 0px 0px var(--accent);
}

.btn-text {
  color: var(--black);
  font-weight: 600;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: var(--med);
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}
.btn-text::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 500%);
  content: "";
  display: block;
  height: 2px;
  width: 0%;
  background: var(--black);
  transition: var(--med);
  transform-origin: center left;
}
.btn-text span {
  transition: var(--fast);
  font-size: 20px;
}
.btn-text:hover::after {
  width: 100%;
}
.btn-text:hover span {
  padding-left: 5px;
}/*# sourceMappingURL=App.css.map */